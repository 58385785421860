import React, { useState, useEffect } from 'react'
import { useStaticQuery, Link, graphql } from 'gatsby'
import { CSSTransition } from 'react-transition-group'

import Content from 'components/Content'
import CustomLink from 'components/CustomLink'
import { getLanguage, setLanguage } from 'services/language'

// CSS
import 'styles/Header.scss'

// Images

import arrowDown from 'img/arrow-down.svg'
import phoneIcon from 'img/phone.svg'
import yachtsLogo from 'img/yachts_logo.svg'
import LanguageSwitch from './LanguageSwitch'

const Header = () => {
  const [isCollapsed, setCollapsed] = useState(true)
  const language = getLanguage()

  function setCollapsedAndOverflow(boolean) {
    setCollapsed(boolean)
    document.querySelector('html').classList.toggle('menu-is-open')
  }

  useEffect(() => {
    if (isCollapsed)
      document.querySelector('html').classList.remove('menu-is-open')
  }, [isCollapsed])

  const { headerNL, headerEN, menuNL, menuEN } = useStaticQuery(graphql`
    query {
      headerNL: wordpressWpComponenten(wordpress_id: { eq: 226 }) {
        ...headerContent
      }

      headerEN: wordpressWpComponenten(wordpress_id: { eq: 227 }) {
        ...headerContent
      }
      menuNL: wordpressMenusMenusItems(term_id: { eq: 27 }) {
        ...menuContent
      }

      menuEN: wordpressMenusMenusItems(term_id: { eq: 28 }) {
        ...menuContent
      }
    }

    fragment headerContent on wordpress__wp_componenten {
      acf {
        top {
          text
        }
        links {
          title
          path
        }
      }
    }

    fragment menuContent on wordpress__menus_menus_items {
      items {
        title
        url
        wordpress_id
        child_items {
          title
          url
          wordpress_id
        }
      }
    }
  `)

  const headerContent = language === 'nl_NL' ? headerNL : headerEN

  const menuContent = language === 'nl_NL' ? menuNL : menuEN

  return (
    <nav className="header position-relative">
      <div className="header-top w-100">
        <div className="container d-flex h-100 justify-content-end align-items-center">
          <Content content={headerContent.acf.top.text} />
          <LanguageSwitch />
        </div>
      </div>
      <div className="navbar-container container">
        <div className="navbar-brand">
          <Link
            to={language === 'nl_NL' ? '/' : '/en/home/'}
            className="navbar-item"
          >
            <div className="veritec-logo">
              {/* <span>veritec.</span> */}
              <img src={yachtsLogo} alt="" />
            </div>
          </Link>
        </div>

        <button
          type="button"
          onClick={() => setCollapsedAndOverflow(!isCollapsed)}
          className={`navbar-collapse-button${
            isCollapsed ? ' navbar-collapse-button-collapsed' : ''
          } text-center d-block d-lg-none`}
        >
          {isCollapsed ? (
            <>
              <BurgerIcon />
            </>
          ) : (
            <span>
              <XIcon />
            </span>
          )}
        </button>

        <HeaderMenuMobile
          language={language}
          headerContent={headerContent}
          links={menuContent.items}
          isCollapsed={isCollapsed}
          setCollapse={setCollapsedAndOverflow}
        />
        <HeaderMenuDesktop links={menuContent.items} />
      </div>
    </nav>
  )
}

const HeaderMenuMobile = ({ language, headerContent, links, isCollapsed }) => (
  <CSSTransition
    in={!isCollapsed}
    timeout={300}
    unmountOnExit
    classNames="header-menu"
  >
    <div className="navbar-menu mobile-menu">
      <ul className="navbar-items">
        <HeaderMenuMobileItems links={links} />
      </ul>

      <div className="header-top d-block w-100">
        <div className="container d-flex flex-column h-100 justify-content-end align-items-center">
          <Content
            className="header-top-text"
            content={headerContent.acf.top.text}
          />

          <LanguageSwitch />
        </div>
      </div>
    </div>
  </CSSTransition>
)

const HeaderMenuDesktop = ({ links }) => (
  <ul className="navbar-items d-none d-lg-flex">
    <HeaderMenuItems links={links} />
  </ul>
)

const HeaderMenuMobileItems = ({ links }) => (
  <>
    {links.map(({ title, url, child_items }, key) => (
      <li key={key}>
        {url === '' ? (
          <Link to={url}>{title}</Link>
        ) : (
          <span
            className={`header-a${child_items !== null ? ' has-children' : ''}`}
          >
            <CustomLink to={url}>{title}</CustomLink>

            {child_items !== null && (
              <>
                {child_items.map((childItem, key) => (
                  <li key={key} className="sub-menu-item">
                    <CustomLink to={childItem.url}>
                      <Content content={childItem.title} />
                    </CustomLink>
                  </li>
                ))}
              </>
            )}
          </span>
        )}
      </li>
    ))}

    <li className="call">
      <a href="tel:+31332997687">
        {getLanguage() === 'nl_NL'
          ? `Direct personeel nodig`
          : `Urgent need for staff?`}
        <div className="call-cta">
          <img className="call-pointer" src={arrowDown} alt="" />
          <div className="call-button button">
            <img src={phoneIcon} alt="" />
            +31 (0) 33 299 76 87
          </div>
        </div>
      </a>
    </li>
  </>
)

const HeaderMenuItems = ({ links }) => (
  <>
    {links.map(({ title, url, child_items }, key) => (
      <li key={key}>
        {url === '' ? (
          <Link to={url}>{title}</Link>
        ) : (
          <span
            className={`header-a${child_items !== null ? ' has-children' : ''}`}
          >
            <CustomLink to={url}>{title}</CustomLink>

            {child_items !== null && (
              <>
                <ul className="sub-menu">
                  {child_items.map((childItem, key) => (
                    <li key={key} className="sub-menu-item">
                      <CustomLink to={childItem.url}>
                        <Content content={childItem.title} />
                      </CustomLink>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </span>
        )}
      </li>
    ))}

    <li className="call">
      <a href="tel:+31332997687">
        {getLanguage() === 'nl_NL'
          ? `Direct personeel nodig`
          : `Urgent need for staff?`}
        <div className="call-cta">
          <img className="call-pointer" src={arrowDown} alt="" />
          <div className="call-button button">
            <img src={phoneIcon} alt="" />
            +31 (0) 33 299 76 87
          </div>
        </div>
      </a>
    </li>
  </>
)

const BurgerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="feather feather-menu"
      viewBox="0 0 24 24"
    >
      <path d="M3 12L21 12" />
      <path d="M3 6L21 6" />
      <path d="M3 18L21 18" />
    </svg>
  )
}

const XIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className="feather feather-x"
      viewBox="0 0 24 24"
    >
      <path d="M18 6L6 18" />
      <path d="M6 6L18 18" />
    </svg>
  )
}

export default Header
