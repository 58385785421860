/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import enFlag from 'img/flag-en.svg'
import nlFlag from 'img/flag-nl.svg'

// Language
import { getLanguage, setLanguage } from 'services/language'

const LanguageSwitch = () => {
  const {
    pages,
    posts,
    site: { pathPrefix },
  } = useStaticQuery(graphql`
    {
      site {
        pathPrefix
      }
      pages: allWordpressPage {
        edges {
          node {
            path
            locale: wpml_current_locale
            translations: wpml_translations {
              locale
              path: href
            }
          }
        }
      }
      posts: allWordpressPost {
        edges {
          node {
            path
            locale: wpml_current_locale
            translations: wpml_translations {
              locale
              path: href
            }
          }
        }
      }
    }
  `)

  const language = getLanguage()

  let pathname = ''

  if (typeof window !== 'undefined') {
    pathname = window.location.pathname
  }

  if (pathPrefix) {
    pathname = pathname.replace(pathPrefix, '')
  }

  const allPages = [...posts.edges, ...pages.edges]

  let toPage = null

  allPages.forEach(({ node }) => {
    if (node.path === pathname) {
      // eslint-disable-next-line prefer-destructuring
      toPage = node.translations[0]
    }

    const translations = node.translations.find(({ path }) => path === pathname)

    if (translations && Object.keys(translations) > 0) {
      toPage = node
    }
  })

  const mainLanguage = language === 'nl_NL'

  if (!toPage) {
    toPage = mainLanguage ? { path: '/en/' } : { path: '/' }
  }

  if (toPage.path === '/en/sample-page/') {
    toPage.path = '/en/'
  }

  const switchLanguage = () => {
    setLanguage(toPage)
  }

  return (
    <button
      className="header__language"
      type="button"
      onClick={() => switchLanguage(toPage)}
    >
      <img src={language === 'nl_NL' ? nlFlag : enFlag} alt="Language flag" />
      <span>{language === 'nl_NL' ? 'EN' : 'NL'}</span>
    </button>
  )
}

export default LanguageSwitch
